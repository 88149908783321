// useful functions 


function mws_alert(str) {
    // todo : use a foundation modal instead
    return alert(str);
}

function mws_confirm(str) {
    // todo : use a foundation modal instead
    return confirm(str);
}

/*! cookie function. get, set, or forget a cookie. [c]2014 @scottjehl, Filament Group, Inc. Licensed MIT */

var cookie = function( name, value, days ){
    // if value is undefined, get the cookie value
    if( value === undefined ){
        var cookiestring = "; " + document.cookie;
        var cookies = cookiestring.split( "; " + name + "=" );
        if ( cookies.length === 2 ){
            return cookies.pop().split( ";" ).shift();
        }
        return null;
    }
    else {
        // if value is a false boolean, we'll treat that as a delete
        if( value === false ){
            days = -1;
        }
        var expires = "";
        if ( days ) {
            var date = new Date();
            date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
            expires = "; expires="+date.toGMTString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }
};
jQuery("#choix")
  .on("on.zf.toggler", function(e) {
    cookie("choix-state", "closed");
  })
  .on("off.zf.toggler", function(e) {
    cookie("choix-state", "open");
  });

//if (cookie("choix-state") != 'closed') $('#choix').foundation('toggle');


jQuery(document).scroll(function() {
    var y = jQuery(this).scrollTop();
    if (y > 800) {
      jQuery('#to_top').fadeIn(1000);
    } else {
      jQuery('#to_top').fadeOut(1000);
    }
  });

jQuery(window).on('load', function () {
    var archive_grids = jQuery('.archive-grid');
    if (archive_grids.length) { 
      Foundation.reInit('equalizer');
    }
});